import moment from 'moment'

export const getRegistrationFormConfig = sevaDates => {

    const allSlots = "All Slots"

    return sevaDates ? [{
        section: 'Select the slots in which you are available',
        fields: sevaDates.map(sevaDate => {
            const slots = JSON.parse(sevaDate.slots)
            return {
                title: `${moment(sevaDate.date).format('dddd, Do MMM YYYY')} (${sevaDate.description})`,
                name: sevaDate.date,
                type: 'mcq',
                options: [allSlots, ...slots].map(o => {
                    return { value: o, disabled: v => o !== allSlots && v.indexOf(allSlots) != -1 }
                }),
                mandatory: false,
                desc: "Please tick ✓ the slot(s) for which you are available for",
                post: v => v.indexOf(allSlots) != -1 ? [allSlots] : (slots.length == v.length ? [allSlots] : v)
            }
        })
    }] : null
}