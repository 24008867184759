import "./index.css"
import moment from 'moment'
import { useNavigate } from "react-router-dom"
import DP from "../../../../../components/custom/dp"
import { useGlobal } from "../../../../../context/global"

const Seva = props => {

    const { seva } = props
    const { name, date, lastDate, hasRegistered, isServiceListReady } = seva
    const { auth } = useGlobal()

    const navigate = useNavigate()

    const handleRegister = () => {
        navigate("/register", { state: seva })
    }

    const isLastDateElapsed = moment(lastDate, 'YYYY-MM-DD').isBefore(moment().startOf('day'))

    return <div className="flex-col volseva-main">
        <div className="volseva-cont">
            <div className="flex-row volseva-2">
                <div>
                    <DP url={`https://cdn.iskconmysore.org/content?path=sevabase/seva/${seva.id}.jpg`} size={[16, 6]} enlarge />
                </div>
                <div className="flex-col volseva-1">
                    <div className="bold volseva-name">{name}</div>
                    <div className="">{moment(date).format("dddd, Do MMM YYYY")}</div>
                </div>
            </div>
            {!isLastDateElapsed ? <div className="volseva-info">{hasRegistered ? 'You have registered for this seva' : `Last date for registration: ${moment(lastDate).format("Do MMM YYYY")}`}</div> : null}
            {!isLastDateElapsed ? <button onClick={handleRegister} className="volseva-reg">{hasRegistered ? 'Modify Availability' : 'Register Now'}</button> : null}
            {isServiceListReady ? <a href={`https://vol.iskconmysore.org/vol?name=${encodeURIComponent(auth.user.name)}`}>
                <button className="volseva-reg">{'View your service'}</button>
            </a> : null}
        </div>
        <hr style={{ opacity: 0 }} />
    </div>
}

export default Seva